.product-thumbnail {
  width: 3.75rem;
}
.product-title {
  max-width: 15vw;
  min-width: 12.5rem;
}

.product-actions {
  width: 8rem;
}
